import React, { useRef, useState, useEffect } from "react"
import { Button } from "primereact/button"
import { Card } from "primereact/card"
import "primereact/resources/themes/bootstrap4-light-blue/theme.css"
import "primeicons/primeicons.css"
import "../css/app.css"
import fetching from "../dbFiles/fetching"
import zamowienia from "../images/zamowienia.jfif"
import "primereact/resources/primereact.css"
import { IconField } from "primereact/iconfield"
import { InputIcon } from "primereact/inputicon"
import { InputText } from "primereact/inputtext"
import { InputNumber } from "primereact/inputnumber"
import { DataTable } from "primereact/datatable"
import { Dialog } from "primereact/dialog"
import { Column } from "primereact/column"
import { Tag } from "primereact/tag"
import { Toast } from "primereact/toast"
import images from "../importImages.js"
import { Badge } from "primereact/badge"
import language from "./utils/language.json"
import { FilterMatchMode, FilterOperator } from "primereact/api"

// import * as adres from "./adresy.js"

const lang = window.navigator.language
let jezyk
if (lang === "pl-PL") {
  jezyk = "pl"
} else {
  jezyk = "ru"
}

const Zamowienia = (user) => {
  const [showForm, setShowForm] = useState(false)

  const [szukajka, setSzukajka] = useState('')
  const [filters, setFilters] = useState({
    global: {
      value: "",
      matchMode: FilterMatchMode.CONTAINS,
    },
  })
  const [slideOut, setSlideOut] = useState(false)
  const [products, setProducts] = useState([])
  const [expandedRows, setExpandedRows] = useState(null)
  const [quantity, setQuantity] = useState(0)
  const [showQuantityDialog, setShowQuantityDialog] = useState(false)
  const [showCartDialog, setShowCartDialog] = useState(false)
  const toast = useRef(null)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [koszyk, setKoszyk] = useState([])
  const inputRef = useRef(null)

  // Funkcja do budowania zagnieżdżonej struktury
  function buildNestedStructure(data) {
    const root = []

    const map = {}

    data.forEach((item) => {
      const kodParts = item.KOD.match(/.{1,3}/g) // Dziel KOD na części po 3 znaki
      const parentKod = kodParts.slice(0, -1).join("")
      const currentKod = kodParts.join("")

      const newItem = {
        ...item,
        NAZWA: lang !== "pl-PL" ? item.NAZWA_RU : item.NAZWA,
        NazwaRuToSave: item.NAZWA_RU,
        NazwaPlToSave: item.NAZWA,
        //jeśli istnieje obrazek dla danego kodu to go dodaj
        Image: images[item.KOD + ".jpg"],
        children: [],
      }

      map[currentKod] = newItem

      if (parentKod) {
        if (map[parentKod]) {
          map[parentKod].children.push(newItem)
        } else {
          map[parentKod] = { children: [newItem] }
        }
      } else {
        root.push(newItem)
      }
    })
    //order parents by NAZWA
    root.sort((a, b) => a.NAZWA.localeCompare(b.NAZWA))
    //order children by NAZWA
    root.forEach((parent) => {
      parent.children.sort((a, b) => a.NAZWA.localeCompare(b.NAZWA))
    })

    return root
  }

  const loadData = async () => {
    await fetching.drzewoProduktow().then((res) => {
      const nestedStructure = buildNestedStructure(res)
      console.log(nestedStructure)

      // if (lang == "pl-PL") {
      //   nestedStructure.forEach((product) => {
      //     product.NAZWA = product.NAZWA_RU
      //   })
      // }
      setProducts(nestedStructure)
    })
  }

  useEffect(() => {
    console.log(user)
    loadData()
    const cart = JSON.parse(localStorage.getItem("koszyk"))
    if (cart) {
      setKoszyk(cart)
    }
  }, [])

  const handleSerwis = () => {
    setSlideOut(true)
    setTimeout(() => {
      setShowForm(true)
    }, 100) // Duration of slide-out animation
  }

  const allowExpansion = (rowData) => {
    return rowData.children?.length > 0
  }

  const findParent = (kod, products) => {
    for (let product of products) {
      if (product.children?.some((child) => child.KOD === kod)) {
        return product
      }
      const foundInChild = findParent(kod, product.children || [])
      if (foundInChild) return foundInChild
    }
    return null
  }

  const getParentPathString = (product, products) => {
    const path = []
    const pathRuToSave = []
    const pathPlToSave = []
    let currentProduct = product
    while (currentProduct) {
      path.unshift(currentProduct.NAZWA)
      pathRuToSave.unshift(currentProduct.NazwaRuToSave)
      pathPlToSave.unshift(currentProduct.NazwaPlToSave)
      currentProduct = findParent(currentProduct.KOD, products)
    }
    return [
      path.join(" > "),
      pathRuToSave.join(" > "),
      pathPlToSave.join(" > "),
    ]
  }
  const getParentImage = (product, products) => {
    const path = []
    let Image = null
    let currentProduct = product
    while (currentProduct) {
      path.unshift(currentProduct.NAZWA)
      currentProduct = findParent(currentProduct.KOD, products)
      if (currentProduct?.Image) {
        Image = currentProduct.Image
      }
    }
    return Image
  }

  const handleQuantityDialog = (rowData) => {
    const parentPathFull = getParentPathString(rowData, products)
    const parentPath = parentPathFull[0]
    console.log(parentPath)
    //dodaj do obiektu obrazek produktu z najstarszego rodzica
    const Image = getParentImage(rowData, products)

    setSelectedProduct({
      ...rowData,
      Image2: Image,
      parentPath: parentPath,
      NazwaRuToSave: parentPathFull[1],
      NazwaPlToSave: parentPathFull[2],
    })
    setQuantity(1)
    setShowQuantityDialog(true)
  }

  const rowExpansionTemplate = (data) => {
    // console.log(data)
    return (
      <div className="p-3">
        {/* <h5>Orders for {data.name}</h5> */}
        <DataTable
          value={data.children}
          dataKey="KOD"
          emptyMessage={language["Brak produktow"][jezyk]}
          removableSort
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          onRowClick={(e) => onRowClick(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column expander={allowExpansion} className="expander" />
          {/* <Column field="id" header="Id" sortable></Column> */}
          <Column field="Image" header="" body={imageBodyTemplate}></Column>
          <Column
            field="NAZWA"
            // header="Nazwa"
            sortable
            style={{ fontSize: "13px" }}
          ></Column>
          <Column
            field="purchase"
            header=""
            body={(rowData) =>
              rowData.children?.length === 0 ? (
                <Button
                  icon="pi pi-shopping-cart"
                  severity="primary"
                  style={{ paddingLeft: 0 }}
                  text
                  onClick={() => handleQuantityDialog(rowData)}
                />
              ) : null
            }
          />
        </DataTable>
      </div>
    )
  }

  const handleOrder = async () => {
    console.log("Zamówiono")
    await fetching.zamowienie(user, koszyk).then((res) => {
      console.log(res)
    })
  }

  const headerSerwis = (
    <div style={{ width: "100%" }}>
      <img
        alt="zamowienia"
        src={zamowienia}
        style={{ borderRadius: "20px", padding: "10px" }}
      />
    </div>
  )

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = { ...filters }
    _filters["global"].value = value

    setFilters(_filters)
    setSzukajka(value)
  }

  const header = (
    <div className="flex flex-wrap justify-content-end gap-2">
      <IconField iconPosition="left">
        <InputIcon className="pi pi-search"> </InputIcon>
        <InputText
          v-model="value1"
          placeholder={language.Szukaj[jezyk]}
          value={szukajka}
          style={{ width: "100%" }}
          onChange={onGlobalFilterChange}
        />
      </IconField>
    </div>
  )

  const footerContent = (
    <div>
      <Button
        label={language.Anuluj[jezyk]}
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => setShowQuantityDialog(false)}
      />
      <Button
        icon="pi pi-shopping-cart"
        label={language.Dodaj[jezyk]}
        autoFocus
        className="p-button-primary"
        onClick={() => {
          setShowQuantityDialog(false)
          const newKoszyk = [...koszyk]

          const existingItem = newKoszyk.find(
            (item) => item.KOD === selectedProduct.KOD
          )
          if (existingItem) {
            existingItem.quantity += quantity
          } else {
            newKoszyk.push({ ...selectedProduct, quantity })
          }

          setKoszyk(newKoszyk)
          localStorage.setItem("koszyk", JSON.stringify(newKoszyk))
          toast.current.show({
            severity: "success",
            summary: `${language.Zapisano[jezyk]}`,
            detail: `${language.Dodano[jezyk]} ${quantity} ${language.szt[jezyk]}. ${language.produktu[jezyk]} ${selectedProduct.NAZWA} ${language["do koszyka"][jezyk]}`,
            life: 3000,
            style: { marginLeft: "calc(50% - 150px)" },
          })
        }}
      />
    </div>
  )

  const imageBodyTemplate = (product) => {
    return (
      <img
        src={product.Image}
        alt={product.Image}
        className="w-6rem shadow-2 border-round"
        style={{ width: "35px", heiht: "35px" }}
      />
    )
  }

  const onRowClick = (rowData) => {
    if (rowData.children?.length > 0) {
      let _expandedRows = { ...expandedRows }
      if (_expandedRows[rowData.KOD]) {
        delete _expandedRows[rowData.KOD]
      } else {
        _expandedRows[rowData.KOD] = true
      }
      setExpandedRows(_expandedRows)
    }
  }

  const showCart = () => {
    console.log(koszyk)
    //show cart dialog
    setShowCartDialog(true)
  }

  const footerCartContent = (
    <div>
      <Button
        label={language.Zamknij[jezyk]}
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => setShowCartDialog(false)}
      />
      <Button
        label={language.Zamow[jezyk]}
        icon="pi pi-shopping-cart"
        className="p-button-primary"
        onClick={() => {
          console.log(koszyk)
          setShowCartDialog(false)
          setKoszyk([])
          localStorage.removeItem("koszyk")
          handleOrder()
          toast.current.show({
            severity: "success",
            summary: `${language.Zamówiono[jezyk]}`,
            detail: `${language["Zamówiono produkty z koszyka"][jezyk]}`,
            life: 3000,
            style: { marginLeft: "calc(50% - 150px)" },
          })
        }}
      />
    </div>
  )

  const cartRowTemplate = (rowData) => {
    return (
      <>
        <div>{rowData.parentPath}</div>
        <div>
          <strong>{rowData.NAZWA}</strong>
        </div>
      </>
    )
  }

  return (
    <div className="containerSerwis">
      <Toast ref={toast} />
      <Dialog
        header={language.Koszyk[jezyk]}
        footer={footerCartContent}
        visible={showCartDialog}
        style={{ maxHeight: "80vh", display: "flex", flexDirection: "column" }}
        // modal
        onHide={() => setShowCartDialog(false)}
      >
        <div
          className="p-field"
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <DataTable
            value={koszyk}
            dataKey="KOD"
            removableSort
            emptyMessage={language["Koszyk jest pusty"][jezyk]}
            tableStyle={{ width: "90%", padding: "0.5rem 0.5rem" }}
          >
            <Column
              field="Image2"
              header=""
              body={(rowData) => (
                <img
                  src={rowData.Image || rowData.Image2}
                  alt={rowData.Image || rowData.Image2}
                  className="w-6rem shadow-2 border-round"
                  style={{ width: "35px", heiht: "35px" }}
                />
              )}
            />
            <Column
              field="parentPath"
              header={language.Produkt[jezyk]}
              body={cartRowTemplate}
              style={{ fontSize: "13px" }}
            />
            <Column
              field="quantity"
              header={language.Ilosc[jezyk]}
              style={{ fontSize: "13px" }}
            />
            <Column
              field="purchase"
              header=""
              body={(rowData) => (
                <Button
                  icon="pi pi-trash"
                  severity="danger"
                  style={{ paddingLeft: 0 }}
                  text
                  onClick={() => {
                    setKoszyk(koszyk.filter((item) => item.KOD !== rowData.KOD))
                    localStorage.setItem(
                      "koszyk",
                      JSON.stringify(
                        koszyk.filter((item) => item.KOD !== rowData.KOD)
                      )
                    )
                    toast.current.show({
                      severity: "info",
                      summary: `${language.Usunięto[jezyk]}`,
                      detail: `${language.Usunięto[jezyk]} ${language.produkt[jezyk]} ${rowData.NAZWA} ${language["z koszyka"][jezyk]}`,
                      life: 3000,
                      style: { marginLeft: "calc(50% - 150px)" },
                    })
                  }}
                />
              )}
            />
          </DataTable>
        </div>
      </Dialog>
      <Dialog
        header={language["Wybierz ilosc"][jezyk]}
        footer={footerContent}
        visible={showQuantityDialog}
        style={{ width: "75vw", display: "flex", flexDirection: "column" }}
        // modal
        onHide={() => setShowQuantityDialog(false)}
      >
        <div
          className="p-field"
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <h4 style={{ marginBlockStart: "0.5em" }}>
            {selectedProduct?.NAZWA}
          </h4>
          <img
            src={selectedProduct?.Image || selectedProduct?.Image2}
            alt={selectedProduct?.Image || selectedProduct?.Image2}
            className="w-6rem shadow-2 border-round"
            style={{ width: "75px", heiht: "75px" }}
          />
          <h5 style={{ marginBlockEnd: "1em" }}>{language.Ilosc[jezyk]}</h5>
          <InputNumber
            id="quantity"
            ref={inputRef}
            showButtons
            value={quantity}
            min={1}
            max={100}
            step={1}
            buttonLayout="horizontal"
            inputStyle={{ width: "80px", textAlign: "center" }}
            onValueChange={(e) => {
              setQuantity(e.value)
              inputRef.current.getInput().blur()
            }}
          />
        </div>
      </Dialog>
      <Card
        title={language.Zamowienie[jezyk]}
        subTitle={language["Zamow brakujace czesci"][jezyk]}
        className={`card ${slideOut ? "slide-out" : ""}`}
        onClick={handleSerwis}
        //   footer={footerSerwis}
        header={headerSerwis}
      >
        {/* <div className="m-0"></div> */}
      </Card>
      <div
        className={`form-container ${showForm ? "show" : ""}`}
        style={{
          maxHeight: `${koszyk.length > 0 ? "76vh" : "80vh"}`,
          width: "100%",
          overflow: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "1rem",
          }}
        >
          <Button
            icon="pi pi-arrow-left"
            severity="secondary"
            label={language.Wroc[jezyk]}
            style={{ paddingLeft: 0 }}
            text
            onClick={() => {
              setShowForm(false)
              setTimeout(() => {
                setSlideOut(false)
              }, 300)
            }}
          />
          <Button
            icon="pi pi-shopping-cart"
            className="p-overlay-badge"
            severity="primary"
            // style={{ paddingLeft: 0 }}
            text
            onClick={() => showCart()}
          >
            <Badge
              value={koszyk.length}
              severity="info"
              style={{ right: "8px", top: "8px" }}
            ></Badge>
          </Button>
        </div>
        <div>
          <DataTable
            value={products}
            filters={filters}
            globalFilterFields={["NAZWA"]}
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            rowExpansionTemplate={rowExpansionTemplate}
            dataKey="KOD"
            header={header}
            emptyMessage={language["Brak produktow"][jezyk]}
            removableSort
            tableStyle={{ maxWidth: "100%" /* padding: "0.5rem 0.5rem"  */ }}
            onRowClick={(e) => onRowClick(e.data)}
          >
            <Column expander={allowExpansion} iconClassName="expander" />
            <Column
              field="Image"
              header=""
              body={imageBodyTemplate}
              style={{ width: "10%" }}
            />
            <Column
              field="NAZWA"
              // header="Nazwa"
              sortable
              style={{ fontSize: "13px" }}
            />
            <Column
              field="purchase"
              header=""
              body={(rowData) =>
                rowData.children?.length === 0 ? (
                  <Button
                    icon="pi pi-shopping-cart"
                    severity="primary"
                    style={{ paddingLeft: 0 }}
                    text
                    onClick={() => handleQuantityDialog(rowData)}
                  />
                ) : null
              }
            />
          </DataTable>
        </div>
      </div>
      {koszyk.length > 0 ? (
        //wyświetl pasek na dole ekranu z informacją o ilości produktów w koszyku i przyciskiem do otwarcia koszyka
        <div
          className="fixed bottom-0 w-full bg-white shadow-2"
          style={{
            zIndex: 1001,
            borderTop: "1px solid #ebebeb",
            height: "8vh",
            display: "flex",
            position: "fixed",
            bottom: "56px",
            left: 0,
            width: "100%",
            backgroundColor: "#f8f9fa",
          }}
        >
          <div
            className="flex justify-between p-2"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Tag
                value={koszyk.length}
                severity="info"
                style={{
                  fontSize: "1rem",
                  paddingLeft: "0.5rem",
                  paddingRight: "0.5rem",
                  marginLeft: "10px",
                }}
              />
              <span className="text-gray-500" style={{ marginLeft: "10px" }}>
                {language["Produktow w koszyku"][jezyk]}
              </span>
            </div>
            <Button
              label={language.Koszyk[jezyk]}
              icon="pi pi-shopping-cart"
              className="p-button-text"
              onClick={() => showCart()}
            />
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default Zamowienia
