import "../css/loginStyle.css"
import React, { useRef, useState } from "react"
import logo from "../images/logo.png"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import LoginIcon from "@mui/icons-material/Login"
// import * as adres from "./adresy.js"
import * as adres from "../adresy"
let port = adres.config.url

const lang = window.navigator.language
let jezyk
if (lang === "pl-PL") {
  jezyk = "polski"
} else {
  jezyk = "onucowy"
}

const Login = () => {
  const ref = useRef()
  const [pin, setPin] = useState('');
  const [regno, setRegno] = useState('');

  const handleEnter = (e) => {
    if (e.keyCode === 13) {
      handleSubmit()
    }
  }
  const handleSubmit = async () => {
    if (pin === "" || regno === "") {
      alert("Wypełnij wszystkie pola")
      return
    }

    fetch(port + "/loginKierowca", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pin: pin,
        regno: regno,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.text() //.json()
        } else {
          alert("Błędny PIN lub nr rejestracyjny")
        }
      })
      .then((data) => {
        console.log(data)
        if (data?.error == null) {
          window.location.href = "/"
        } else {
          alert("Błędny PIN lub nr rejestracyjny")
        }
      })
  }

  return (
    <div id="id01-login" className="modal-login">
      <form
        className="modal-content-login animate-login"
        id="forma-login"
        method="post"
      >
        <div className="imgcontainer-login">
          <img className="logo-login" src={logo} alt="logo" />
        </div>
        <div className="container-login">
          <TextField
            variant="outlined"
            onKeyDown={handleEnter}
            onChange={(e) => setPin(e.target.value)}
            name="pin"
            id="pin-login"
            ref={ref}
            type="number"
            placeholder="PIN"
            style={{ marginTop: "10px", marginBottom: "10px" }}
          />
          <TextField
            variant="outlined"
            onKeyDown={handleEnter}
            onChange={(e) => setRegno(e.target.value)}
            name="regno"
            id="regno-login"
            ref={ref}
            type="text"
            placeholder="Nr rejestracyjny"
          />
          <Button
            variant="contained"
            color="success"
            endIcon={<LoginIcon />}
            size="large"
            style={{ marginTop: "10px", marginBottom: "10px" }}
            onClick={handleSubmit}
          >
            Zaloguj
          </Button>
        </div>
      </form>
    </div>
  )
}

export default Login
