// src/DriversTable.js
import React, { useState, useEffect, useMemo, useRef } from "react"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Button } from "primereact/button"
import fetching from "../dbFiles/fetching"
import { OverlayPanel } from "primereact/overlaypanel"
import { Tooltip } from "primereact/tooltip"
import NoPhotographyIcon from "@mui/icons-material/NoPhotography"
import { FilterMatchMode, FilterOperator } from "primereact/api"
import { InputText } from "primereact/inputtext"
import { InputIcon } from "primereact/inputicon"
import { IconField } from "primereact/iconfield"
import language from "./utils/language.json"

const lang = window.navigator.language
let jezyk
if (lang === "pl-PL") {
  jezyk = "pl"
} else {
  jezyk = "ru"
}

const DriversTable = () => {
  const [drivers, setDrivers] = useState([])
  const [selectedDrivers, setSelectedDrivers] = useState([])
  const [loading, setLoading] = useState(false)
  const [szukajka, setSzukajka] = useState('')
  const [filters, setFilters] = useState({
    global: {
      value: "",
      matchMode: FilterMatchMode.CONTAINS,
    },
  })
  const op = useRef(null)



  const fetchContacts = async () => {
    setLoading(true)
    const response = await fetching.fetchContacts().then((data) => {
      const contacts = data.map((contact) => {
        return {
          WorkerID: contact.WorkerID,
          Name: contact.Name,
          ForName: contact.ForName,
          PersonalPhone1: contact.PersonalPhone1,
          isPhoto: contact.isPhoto,
        }
      })
      setDrivers(contacts)
    })
    setLoading(false)
    return response
  }
  // Funkcja do pobrania danych z API
  useEffect(() => {
    fetchContacts()
  }, [])

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = { ...filters }
    _filters["global"].value = value

    setFilters(_filters)
    setSzukajka(value)
  }

  // Funkcja do generowania pliku VCard dla wielu kontaktów
  const generateMultipleVCard = (contacts) => {
    let vCardData = ""

    contacts.forEach((contact) => {
      vCardData += `BEGIN:VCARD\r\n`
      vCardData += `VERSION:3.0\r\n`
      vCardData += `FN:${contact.Name} ${contact.ForName}\r\n`
      vCardData += `N:${contact.ForName};${contact.Name};;;\r\n`
      vCardData += `TEL;TYPE=CELL:${contact.PersonalPhone1}\r\n`

      if (contact.Photo) {
        vCardData += `PHOTO;TYPE=JPEG;ENCODING=BASE64:${contact.Photo}\r\n`
      }

      vCardData += `END:VCARD\r\n`
    })

    const blob = new Blob([vCardData], { type: "text/vcard" })
    const url = URL.createObjectURL(blob)

    const link = document.createElement("a")
    link.href = url
    link.download = "contacts.vcf"
    link.click()
  }

  // Funkcja do obsługi dodawania kontaktów
  const addToPhoneBook = async () => {
    setLoading(true)
    //wyciągnij z bazy zdjęcia kierowców
    // console.log(selectedDrivers)
    const contactsPhotos = await fetching
      .fetchPhotos(selectedDrivers)
      .then((data) => {
        // console.log(data)
        const contacts = data.map((contact) => {
        //   console.log(contact)
          let base64String

          if (contact.Photo !== null) {
            let binary = ""
            const bytes = new Uint8Array(contact.Photo.data)
            const len = bytes.byteLength

            for (let i = 0; i < len; i++) {
              binary += String.fromCharCode(bytes[i])
            }
            base64String = btoa(binary)
          }
          const selectedDriver = selectedDrivers.find(
            (e) => e.WorkerID === contact.WorkerID
          )
          return {
            WorkerID: selectedDriver.WorkerID,
            Name: selectedDriver.Name,
            ForName: selectedDriver.ForName,
            PersonalPhone1: selectedDriver.PersonalPhone1,
            isPhoto: selectedDriver.isPhoto,
            Photo: contact.Photo !== null ? base64String : null,
          }
        })
        return contacts
      })
      .finally(() => {
        setLoading(false)
      })
    if (selectedDrivers.length === 0) {
      alert(language["Wybierz przynajmniej jeden rekord"][jezyk])
      return
    }

    // Wywołaj funkcję do generowania pliku VCard z wybranymi rekordami
    generateMultipleVCard(contactsPhotos)
  }

  const memoizedDrivers = useMemo(() => drivers, [drivers])

  const header = (
    <div className="flex flex-wrap justify-content-end gap-2">
      <IconField iconPosition="left">
        <InputIcon className="pi pi-search"> </InputIcon>
        <InputText
          v-model="value1"
          placeholder={language.Szukaj[jezyk]}
          value={szukajka}
          style={{ width: "100%" }}
          onChange={onGlobalFilterChange}
        />
      </IconField>
    </div>
  )

  return (
    <div style={{ marginTop: "8vh", display: "flex", flexDirection: "column" }}>
      <Button
        disabled={selectedDrivers.length === 0 || loading}
        onClick={addToPhoneBook}
        icon="pi pi-download"
        label={language["Dodaj do kontaktów"][jezyk]}
        style={{ margin: "10px" }}
      />
      <DataTable
        className="p-datatable-sm"
        filters={filters}
        globalFilterFields={["Name", "ForName", "PersonalPhone1"]}
        size="small"
        virtualScrollerOptions={{ itemSize: 46 }}
        scrollable
        scrollHeight="calc(100vh - 10vh - 155px)"
        style={{ width: "100%" }}
        header={header}
        loading={loading}
        value={memoizedDrivers}
        selection={selectedDrivers}
        onSelectionChange={(e) => setSelectedDrivers(e.value)}
        dataKey="PersonalPhone1"
        removableSort
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: "3em" }}
        ></Column>
        <Column field="Name" header={language.Nazwisko[jezyk]} sortable></Column>
        <Column field="ForName" header={language["Imię"][jezyk]} sortable ></Column>
        {/* <Column field="PersonalPhone1" header="Telefon"></Column> */}
        <Column
          field="info"
          header={language.Info[jezyk]}
          body={(rowData) => (!rowData.isPhoto ? <NoPhotographyIcon /> : null)}
        ></Column>
      </DataTable>
    </div>
  )
}

export default DriversTable
