import React, { useRef, useState } from "react"
import "primereact/resources/themes/bootstrap4-light-blue/theme.css"
import "primeicons/primeicons.css"
import "../css/app.css"
import driverapp from "../images/driverapp.jfif"
import BottomNavigation from "./utils/bottomNavigation.js"
import { Menubar } from "primereact/menubar"
import Godziny from "./godziny.js"
import Serwis from "./serwis.js"
import Zamowienia from "./zamowienia.js"
import Telefony from "./telefony.js"
import language from "./utils/language.json"
import * as adres from "../adresy.js"
let port = adres.config.url

// import * as adres from "./adresy.js"

// const port = "http://192.168.2.233:3001"
const lang = window.navigator.language
let jezyk
if (lang === "pl-PL") {
  jezyk = "pl"
} else {
  jezyk = "ru"
}


// const start = <img alt="logo" src={logo} className="logoGrupa" />


const items = [
  // {
  //   label: "Strona główna",
  //   icon: "pi pi-home",
  // },
  // {
  //     label: 'Features',
  //     icon: 'pi pi-star'
  // },
  // {
  //     label: 'Projects',
  //     icon: 'pi pi-search',
  //     items: [
  //         {
  //             label: 'Components',
  //             icon: 'pi pi-bolt'
  //         },
  //         {
  //             label: 'Blocks',
  //             icon: 'pi pi-server'
  //         },
  //         {
  //             label: 'UI Kit',
  //             icon: 'pi pi-pencil'
  //         },
  //     ]
  // },
  {
    label: language.Wyloguj[jezyk],
    icon: "pi pi-sign-out",
    command: () => {
      fetch(port + "/logoutKierowca", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res)
        .then((data) => {
          if (data.status === 200) {
            window.location.href = "/"
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
]

const DriverApp = (user) => {
  const [zakladka, setZakladka] = useState(0)
  console.log(user)
  const end = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <h3 style={{ display: "inline-flex", color: "#007bff" }}>
        {user.user.ForName} {user.user.Name}
      </h3>
    <img
      alt="DriverApp"
      src={driverapp}
      style={{
        borderRadius: "5px",
        marginLeft: "20px",
        width: "45px",
        height: "45px",
        // marginTop: "5px",
      }}
    />
    </div>
  )

  return (
    <div style={{height:"auto", backgroundColor: "#f0f0f0", overflowX: "hidden"}}>
      <div style={{height:"100%"}}>
      <Menubar
        /* start={start} */ end={end}
        model={items}
        className="menubar"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: 1000,
          height: "8vh",
          backgroundColor: "#f8f9fa",
          borderBottom: "1px solid #ebebeb",
        }}
      ></Menubar>
      {zakladka === 0 && <Zamowienia user={user} />}
      {zakladka === 1 && <Godziny user={user} />}
      {zakladka === 2 && <Telefony user={user} />}
      {/* {zakladka === 2 && <Serwis user={user} />} */}
      <BottomNavigation zakladka={zakladka} setZakladka={setZakladka} />
      </div>
    </div>
  )
}

export default DriverApp
