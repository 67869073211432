import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import BuildIcon from '@mui/icons-material/Build';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import language from "./language.json"

const lang = window.navigator.language
let jezyk
if (lang === "pl-PL") {
  jezyk = "pl"
} else {
  jezyk = "ru"
}

export default function FixedBottomNavigation(props) {
  const [value, setValue] = React.useState(0);
  const ref = React.useRef(null);


  return (
    <Box sx={{ pb: 7 }} ref={ref}>
      <CssBaseline />
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            // console.log(newValue)
            props.setZakladka(newValue);
          }}
        >
          <BottomNavigationAction label={language.Zamowienia[jezyk]} icon={<ShoppingCartIcon />} />
          <BottomNavigationAction label={language.Godziny[jezyk]} icon={<QueryBuilderIcon />} />
          <BottomNavigationAction label={language.Telefony[jezyk]} icon={<LocalPhoneIcon />} />
          {/* <BottomNavigationAction label={language.Serwis[jezyk]} icon={<BuildIcon />} /> */}
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
