import React, { useRef, useState, useEffect } from "react"
import { Button } from "primereact/button"
import { Card } from "primereact/card"
import "primereact/resources/themes/bootstrap4-light-blue/theme.css"
import "primeicons/primeicons.css"
import "../css/app.css"
import serwis from "../images/serwis.jfif"
import { RadioButton } from "primereact/radiobutton"
import { TreeSelect } from "primereact/treeselect"
import { InputTextarea } from "primereact/inputtextarea"
import "primereact/resources/primereact.css"
import { FloatLabel } from "primereact/floatlabel"

// import * as adres from "./adresy.js"

const port = "http://192.168.2.233:3001"

const lang = window.navigator.language

const Serwis = (user) => {
  const [showForm, setShowForm] = useState(false)
  const [slideOut, setSlideOut] = useState(false)
  const [nodes, setNodes] = useState(null)
  const [selectedNodeKey, setSelectedNodeKey] = useState(null)
  const [uwaga1, setUwaga1] = useState("")
  const [data, setData] = useState(
    new Date(
      new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)
    )
  )
  const [content, setContent] = useState("")

  //   const loadData = async () => {
  //     await fetching
  //       .godzinyOperacje({ date: data, pesel: user.user.user.pesel })
  //       .then((res) => {
  //         setGodzinyOperacje(res[0])
  //       })

  //     await fetching
  //       .postojeInelo({ date: data, pesel: user.user.user.pesel })
  //       .then((res) => {
  //         setPostojeInelo(res)
  //         const suma = res.reduce((a, b) => a + b.Postoje, 0)
  //         setSumaPostoje(suma)
  //       })
  //   }

  //   useEffect(() => {
  //     console.log(user)
  //     loadData()
  //   }, [data])

  const drzewoWyboru = [
    {
      key: "1",
      label: "Pompa Adblue",
    },
    {
      key: "2",
      label: "Hamulce",
      children: [
        {
          key: "2-1",
          label: "Klocki hamulcowe",
          children: [
            { key: "2-1-1", label: "Lewy przód" },
            { key: "2-1-2", label: "Prawy przód" },
            { key: "2-1-3", label: "Lewy tył" },
            { key: "2-1-4", label: "Prawy tył" },
          ],
        },
        {
          key: "2-2",
          label: "Tarcze hamulcowe",
          children: [
            { key: "2-2-1", label: "Lewy przód" },
            { key: "2-2-2", label: "Prawy przód" },
            { key: "2-2-3", label: "Lewy tył" },
            { key: "2-2-4", label: "Prawy tył" },
          ],
        },
      ],
    },
    {
      key: "3",
      label: "Rozrusznik",
    },
    {
      key: "4",
      label: "Alternator",
    },
    {
      key: "5",
      label: "Akumulator",
    },
    {
      key: "6",
      label: "Koła",
      children: [
        {
          key: "2-1",
          label: "Felgi",
          children: [
            { key: "2-1-1", label: "Lewy przód" },
            { key: "2-1-2", label: "Prawy przód" },
            { key: "2-1-3", label: "Lewy tył" },
            { key: "2-1-4", label: "Prawy tył" },
          ],
        },
        {
          key: "2-2",
          label: "Opony",
          children: [
            { key: "2-2-1", label: "Lewy przód" },
            { key: "2-2-2", label: "Prawy przód" },
            { key: "2-2-3", label: "Lewy tył" },
            { key: "2-2-4", label: "Prawy tył" },
          ],
        },
      ],
    },
    {
      key: "7",
      label: "Filtr powietrza",
    },
    {
      key: "8",
      label: "Filtr paliwa",
    },
    {
      key: "9",
      label: "Filtr kabinowy",
    },
    {
      key: "10",
      label: "Płyn chłodniczy",
    },
    {
      key: "11",
      label: "Płyn hamulcowy",
    },
    {
      key: "12",
      label: "Płyn wspomagania",
    },
    {
      key: "13",
      label: "Płyn spryskiwaczy",
    },
    {
      key: "14",
      label: "Płyn do sprzęgła",
    },
    {
      key: "15",
      label: "Płyn do skrzyni biegów",
    },
    {
      key: "16",
      label: "Płyn do dyferencjału",
    },
    {
      key: "17",
      label: "Płyn do reduktora",
    },
    {
      key: "18",
      label: "Płyn do układu kierowniczego",
    },
    {
      key: "19",
      label: "Płyn do układu pneumatycznego",
    },
    {
      key: "20",
      label: "Płyn do układu hydraulicznego",
    },
    {
      key: "21",
      label: "Płyn do układu hamulcowego",
    },
    {
      key: "22",
      label: "Płyn do układu chłodzenia",
    },
    {
      key: "23",
      label: "Płyn do układu smarowania",
    },
    {
      key: "24",
      label: "Płyn do układu wydechowego",
    },
  ]
  useEffect(() => {
    setNodes(drzewoWyboru)
  }, [])

  const handleSerwis = () => {
    setSlideOut(true)
    setTimeout(() => {
      setShowForm(true)
    }, 100) // Duration of slide-out animation
  }

  const headerSerwis = (
    <div style={{ width: "100%" }}>
      <img
        alt="Jazda"
        src={serwis}
        style={{ borderRadius: "20px", padding: "10px" }}
      />
    </div>
  )

  return (
    <div className="containerSerwis">
      <Card
        title="Nowe zlecenie serwisowe"
        subTitle="Zgłoś usterkę"
        className={`card ${slideOut ? "slide-out" : ""}`}
        onClick={handleSerwis}
        //   footer={footerSerwis}
        header={headerSerwis}
      >
        {/* <div className="m-0"></div> */}
      </Card>
      <div className={`form-container ${showForm ? "show" : ""}`}>
        <Button
          icon="pi pi-arrow-left"
          severity="secondary"
          label="Wróć"
          style={{ paddingLeft: 0 }}
          text
          onClick={() => (
            setShowForm(false),
            setTimeout(() => {
              setSlideOut(false)
            }, 300)
          )}
        />
        <h2>Formularz zgłoszenia usterki</h2>
        <div className="flex flex-wrap gap-3">
          <div
            className="flex align-items-center"
            style={{ marginBottom: "10px", display: "flex" }}
          >
            <RadioButton
              inputId="content1"
              name="content"
              value="znamPrzyczyne"
              onChange={(e) => setContent(e.value)}
              checked={content === "znamPrzyczyne"}
            />
            <label
              htmlFor="content1"
              className="ml-2"
              style={{ marginLeft: "10px" }}
            >
              <b>Znam przyczynę</b>
            </label>
          </div>
          <div
            className="flex align-items-center"
            style={{ marginBottom: "10px", display: "flex" }}
          >
            <RadioButton
              inputId="content2"
              name="content"
              value="nieZnamPrzyczyny"
              onChange={(e) => setContent(e.value)}
              checked={content === "nieZnamPrzyczyny"}
            />
            <label
              htmlFor="content2"
              className="ml-2"
              style={{ marginLeft: "10px" }}
            >
              <b>Nie znam przyczyny</b>
            </label>
          </div>
        </div>
        <div className="flex justify-content-center">
          {content === "znamPrzyczyne" && (
            <div>
              <TreeSelect
                value={selectedNodeKey}
                onChange={(e) => setSelectedNodeKey(e.value)}
                options={nodes}
                className="md:w-50rem w-full"
                placeholder="Wybierz część pojazdu"
                style={{ width: "100%", marginTop: "20px" }}
              ></TreeSelect>
              <FloatLabel
                className="md:w-50rem w-full"
                placeholder="Opis usterki"
                style={{ width: "100%", marginTop: "30px" }}
              >
                <InputTextarea
                  id="opis"
                  autoResize
                  value={uwaga1}
                  onChange={(e) => setUwaga1(e.target.value)}
                  rows={5}
                  cols={30}
                  style={{ width: "100%" }}
                />
                <label htmlFor="opis">Opis usterki</label>
              </FloatLabel>
              <div
                className="flex justify-content-end"
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  label="Zgłoś"
                  icon="pi pi-check"
                  //   className="md:w-50rem w-full"
                />
              </div>
            </div>
          )}
          {content === "nieZnamPrzyczyny" && (
            <div>
              <FloatLabel
                className="md:w-50rem w-full"
                placeholder="Opis usterki"
                style={{ width: "100%", marginTop: "30px" }}
              >
                <InputTextarea
                  id="opis"
                  autoResize
                  value={uwaga1}
                  onChange={(e) => setUwaga1(e.target.value)}
                  rows={5}
                  cols={30}
                  style={{ width: "100%" }}
                />
                <label htmlFor="opis">Opis usterki</label>
              </FloatLabel>
              <div
                className="flex justify-content-end"
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  label="Zgłoś"
                  icon="pi pi-check"
                  //   className="md:w-50rem w-full"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Serwis
