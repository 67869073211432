import * as adres from "../adresy"
let port = adres.config.url

async function godzinyOperacje(data) {
    const res = await fetch(`${port}/godzinyOperacjeKierowca`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        miesiac: new Date(data.date).getMonth() + 1,
        rok: new Date(data.date).getYear() + 1900,
        pesel: data.pesel
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then((data) => {
        return data
      })
    return res
  }
  
async function postojeInelo(data) {
    const res = await fetch(`${port}/postojeIneloKierowca`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        miesiac: new Date(data.date).getMonth() + 1,
        rok: new Date(data.date).getYear() + 1900,
        pesel: data.pesel
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then((data) => {
        return data
      })
    return res
  }
  
async function drzewoProduktow(data) {
    const res = await fetch(`${port}/drzewoProduktow`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      // body: JSON.stringify({
      //   miesiac: new Date(data.date).getMonth() + 1,
      //   rok: new Date(data.date).getYear() + 1900,
      //   pesel: data.pesel
      // }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then((data) => {
        return data
      })
    return res
  }

async function zamowienie(user, data) {
  const dd = {
    user: user.user.user,
    data: data}
    console.log(dd)
    const res = await fetch(`${port}/zamowienie`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user: user.user.user,
        data: data
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then((data) => {
        return data
      })
    return res
  }

  async function stawki(params) {
    const res = await fetch(`${port}/stawki`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        miesiac: new Date(params).getMonth() + 1,
        rok: new Date(params).getYear() + 1900,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then((data) => {
        return data
      })
    return res
  }

  async function fetchContacts(params) {
    const res = await fetch(`${port}/fetchContacts`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      // body: JSON.stringify({
      // }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then((data) => {
        return data
      })
    return res
  }

  async function fetchPhotos(params) {
    const res = await fetch(`${port}/fetchPhotos`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
       body: JSON.stringify({
        drivers: params
       }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then((data) => {
        return data
      })
    return res
  }

  export default {
    godzinyOperacje,
    postojeInelo,
    drzewoProduktow,
    zamowienie,
    stawki,
    fetchContacts,
    fetchPhotos
  }