// import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import React, { useEffect, useState } from "react"
// import './index.css';
// import Form from './forma';
// import Home from './home';
// import Truck from './truck';
import Login from "./components/login"
import DriverApp from "./components/driverapp"
import * as adres from "./adresy"

// import "primereact/resources/themes/lara-dark-blue/theme.css"
import "./App.css"
let port = adres.config.url
// const port = "http://192.168.2.233:3001"
function App() {
  // const token = localStorage.getItem('pin');

  const [token, setToken] = useState(false)
  const [wynik, setWynik] = useState(null)
  const [user, setUser] = useState(null)

  useEffect(() => {
    fetch(port + "/checkLoginKierowca", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
      })
      .then((data) => {
        console.log(data)
        if (data != null) {
          setUser(data)
          setToken(true)
        }
      })
  }, [])

  useEffect(() => {
    setWynik(
      <Routes>
        <Route
          path="/"
          element={token ? <DriverApp user={user} /> : <Login />}
        />
      </Routes>
    )
  }, [token])

  return (
    <div className="App">
      <BrowserRouter>{wynik}</BrowserRouter>
    </div>
  )
}

export default App
