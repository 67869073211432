import React, { useRef, useState, useEffect } from "react"
import { Button } from "primereact/button"
import { Card } from "primereact/card"
import "primereact/resources/themes/bootstrap4-light-blue/theme.css"
import "primeicons/primeicons.css"
import "../css/app.css"
import jazda from "../images/jazda.jfif"
import postoj from "../images/postoj.jfif"
import operacje from "../images/operacje.jfif"
import { Tag } from "primereact/tag"
import fetching from "../dbFiles/fetching"
import { ProgressBar } from "primereact/progressbar"
import { Dialog } from "primereact/dialog"
import language from "./utils/language.json"

const lang = window.navigator.language
let jezyk
if (lang === "pl-PL") {
  jezyk = "pl"
} else {
  jezyk = "ru"
}
// import * as adres from "./adresy.js"

const Godziny = (user) => {
  const [data, setData] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
  )
  const [godzinyOperacje, setGodzinyOperacje] = useState(null)
  const [postojeInelo, setPostojeInelo] = useState(null)
  const [sumaPostoje, setSumaPostoje] = useState(null)
  const [visible, setVisible] = useState(false)
  const [header, setHeader] = useState("")
  const [content, setContent] = useState("")
  const [stawki, setStawki] = useState(null)

  const loadData = async () => {
    await fetching
      .godzinyOperacje({ date: data, pesel: user.user.user.pesel })
      .then((res) => {
        setGodzinyOperacje(res[0])
      })

    await fetching
      .postojeInelo({ date: data, pesel: user.user.user.pesel })
      .then((res) => {
        setPostojeInelo(res)
        const suma = res.reduce((a, b) => a + b.Postoje, 0)
        setSumaPostoje(suma)
      })

    await fetching.stawki(data).then((res) => {
      setStawki(res)
      console.log(res)
    })
  }

  useEffect(() => {
    console.log(user)
    loadData()
  }, [data])

  const handlePostoje = () => {
    setVisible(true)
    setHeader(language.Postoje[jezyk])
    let sumPostoje = 0
    for (let i = 0; i < postojeInelo.length; i++) {
      sumPostoje += postojeInelo[i].Postoje
    }
    setContent(
      <div>
        {postojeInelo?.map((postoj) => {
          return (
            <div key={postoj.Data}>
              <b>
                {postoj.Data}: {Math.floor(postoj.Postoje || 0)} h{" "}
                {Math.abs(((postoj.Postoje || 0) % 1) * 60).toFixed(0)} min.
              </b>
            </div>
          )
        })}
        <br />
        <br />
        <b>
          {language.Postoje[jezyk]+` (€): ${
            stawki ? (
              (
                stawki.find((e) => e.StawkaTyp === "Postoje").Value *
                (sumPostoje || 0)
              ).toFixed(2)
            ) : (
              <ProgressBar mode="indeterminate"></ProgressBar>
            )
          } €`}
        </b>
      </div>
    )
  }

  const handleJazda = () => {
    setVisible(true)
    setHeader(language.Jazda[jezyk])
    setContent(
      <div>
        <b>
          {language.Jazda[jezyk]}:{" "}
          {`${Math.floor(godzinyOperacje?.Jazda || 0)} h
            ${(Math.abs((godzinyOperacje?.Jazda || 0) % 1) * 60).toFixed(
              0
            )} min.`}
        </b>
        <br />
        <br />
        <b>
          {language["Jazda z uczniem"][jezyk]}:{" "}
          {`${Math.floor(godzinyOperacje?.Dyzury || 0)} h
                ${(Math.abs((godzinyOperacje?.Dyzury || 0) % 1) * 60).toFixed(
                  0
                )} min.`}
        </b>
        <br />
        <br />
        <b>
          {language.Jazda[jezyk] +` (€): ${
            stawki ? (
              (
                stawki.find((e) => e.StawkaTyp === "Godziny").Value *
                ((godzinyOperacje?.Jazda || 0) + (godzinyOperacje?.Dyzury || 0))
              ).toFixed(2)
            ) : (
              <ProgressBar mode="indeterminate"></ProgressBar>
            )
          } €`}
        </b>
      </div>
    )
  }

  const handleOperacje = () => {
    setVisible(true)
    setHeader(language.Operacje[jezyk])
    setContent(
      <div>
        <b>
          {language.Operacje[jezyk]}:{" "}
          {`${Math.floor(godzinyOperacje?.Operacje || 0)}`}
        </b>
        <br />
        <br />
        <b>
          { language.Operacje[jezyk] + ` (€): ${
            stawki ? (
              (
                stawki.find((e) => e.StawkaTyp === "Operacje").Value *
                (godzinyOperacje?.Operacje || 0)
              ).toFixed(2)
            ) : (
              <ProgressBar mode="indeterminate"></ProgressBar>
            )
          } €`}
        </b>
      </div>
    )
  }

  const headerJazda = (
    <div style={{ width: "100%" }}>
      <img
        alt="Jazda"
        src={jazda}
        style={{ borderRadius: "20px", padding: "10px" }}
      />
    </div>
  )
  const headerPostoj = (
    <div style={{ width: "100%" }}>
      <img
        alt="Postoj"
        src={postoj}
        style={{ borderRadius: "20px", padding: "10px" }}
      />
    </div>
  )
  const headerOperacje = (
    <div style={{ width: "100%" }}>
      <img
        alt="Postoj"
        src={operacje}
        style={{ borderRadius: "20px", padding: "10px" }}
      />
    </div>
  )
  const footerJazda = (
    <>
      <Button
        label="Szczegóły"
        icon="pi pi-info-circle"
        onClick={handleJazda}
        disabled={godzinyOperacje === null}
      />
    </>
  )
  const footerPostoj = (
    <>
      <Button
        label="Szczegóły"
        icon="pi pi-info-circle"
        onClick={handlePostoje}
        disabled={postojeInelo === null}
      />
    </>
  )
  const footerOperacje = (
    <>
      <Button
        label="Szczegóły"
        icon="pi pi-info-circle"
        onClick={handleOperacje}
        disabled={godzinyOperacje === null}
      />
    </>
  )

  return (
    <div style={{ marginTop: "8vh" }}>
      <Tag
        value={`${data.getFullYear()}-${data.getMonth() + 1 < 10 ? "0" : ""}${
          data.getMonth() + 1
        }`}
        severity="primary"
        style={{
          display: "flex",
          width: "90%",
          height: "50px",
          fontSize: "1em",
          textAlign: "center",
          margin: "auto",
          marginTop: "10px",
        }}
      />
      <div
        className="containerApp"
        style={{
          width: "100%",
        }}
      >
        <Card
          title={language.Jazda[jezyk]}
          subTitle={language["Czas jazdy"][jezyk]}
          footer={footerJazda}
          header={headerJazda}
          className="card"
          // className="md:w-25rem"
          style={{
            display: "flex",
            // flexDirection: "column",
            alignItems: "center",
            lineHeight: "1.0",
            // marginLeft: "20px",
            width: "100%",
            // justifyContent: "space-between",
          }}
        >
          <div className="m-0">
            <b>
              {godzinyOperacje !== null ? (
                `${Math.floor(
                  (godzinyOperacje?.Jazda || 0) + (godzinyOperacje?.Dyzury || 0)
                )} h
                ${(
                  Math.abs(
                    ((godzinyOperacje?.Jazda || 0) +
                      (godzinyOperacje?.Dyzury || 0)) %
                      1
                  ) * 60
                ).toFixed(0)} min.`
              ) : (
                <ProgressBar
                  mode="indeterminate"
                  style={{ height: "6px" }}
                ></ProgressBar>
              )}
            </b>
          </div>
        </Card>
        <Card
          title={language.Postoje[jezyk]}
          subTitle={language["Czas postoju"][jezyk]}
          className="card"
          footer={footerPostoj}
          header={headerPostoj}
          // className="md:w-25rem"
          style={{
            display: "flex",
            // flexDirection: "column",
            alignItems: "center",
            lineHeight: "1.0",
            width: "100%",
          }}
        >
          <div
            className="m-0"
            style={{
              width: "100%",
            }}
          >
            <b>
              {sumaPostoje !== null ? (
                `${Math.floor(sumaPostoje || 0)} h
                ${Math.abs(((sumaPostoje || 0) % 1) * 60).toFixed(0)} min.`
              ) : (
                <ProgressBar
                  mode="indeterminate"
                  style={{ height: "6px" }}
                ></ProgressBar>
              )}
            </b>
          </div>
        </Card>
        <Card
          title={language.Operacje[jezyk]}
          subTitle={language["Zaladunki i rozladunki"][jezyk]}
          footer={footerOperacje}
          header={headerOperacje}
          className="card"
          style={{
            display: "flex",
            alignItems: "center",
            lineHeight: "1.0",
            width: "100%",
          }}
        >
          <div
            className="m-0"
            style={{
              width: "100%",
            }}
          >
            <b>
              {godzinyOperacje !== null ? (
                godzinyOperacje?.Operacje
              ) : (
                <ProgressBar
                  mode="indeterminate"
                  style={{ height: "6px" }}
                ></ProgressBar>
              )}
            </b>
          </div>
        </Card>
      </div>
      <Dialog
        header={header}
        visible={visible}
        dismissableMask={true}
        style={{ width: "60vw" }}
        onHide={() => {
          if (!visible) return
          setVisible(false)
        }}
      >
        {content}
      </Dialog>
    </div>
  )
}

export default Godziny
