function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
      images[item.replace('./', '')] = r(item);
    });
    return images;
  }
  
  const images = importAll(require.context('./images/products', false, /\.(png|jpe?g|svg|webp|jfif)$/));
  
  export default images;